<template>
  <div class="module-batch-operate">
    <div class="module-first">
      <div>
        <el-button type="primary" size="mini" plain>导入数据</el-button>
        <el-button type="primary" size="mini">批量修改类目</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.module-batch-operate {
  .module-first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;
  }
}
</style>
