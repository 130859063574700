<template>
  <div class="page">
    <div v-if="categoryIsLoad" class="category-box">
      <!-- 选择类目 -->
      <div class="category-item">
        <div class="category-key">选择类目:</div>
        <div class="category-val flex-column">
          <div class="category-input">{{ categoryActiveStr }}</div>
          <b-goods-category
            class="m-t-5"
            :ids="categoryRouteIds"
            :isInit="!categoryRouteIds.length"
            @success="getCategoryActiveList"
          />
        </div>
      </div>

      <!-- 批量操作 -->
      <div v-if="categoryLastId" class="category-item">
        <div class="category-key">批量操作:</div>
        <div class="category-val">
          <ModuleBatchOperate :list.sync="goodsList" />
        </div>
      </div>

      <!-- 库存列表 -->
      <div v-if="categoryLastId" class="m-t-5">
        <ModuleGoods ref="refModuleGoods" :categoryLastId="categoryLastId" isShowList />
      </div>
    </div>
  </div>
</template>

<script>
import ModuleBatchOperate from './components/module-batch-operate'
import ModuleGoods from './components/module-goods'
export default {
  components: {
    ModuleBatchOperate,
    ModuleGoods
  },
  data() {
    return {
      // 类目
      categoryIsLoad: false,
      categoryLastId: '',
      categoryRouteIds: [],
      categoryActiveList: [],

      // 模块
      goodsList: [
        {
          gys: '', // 供应商
          wj: '', // 外径
          hd: '', // 厚度
          ylcd: '', // 原料长度
          danjia: '', // 基价
          jiajia: '', // 加价
          shoujia: '', // 售价
          zz: '', // 支重
          bm: '', // 表面
          yd: '', // 硬度
          dj: '' // 等级
        }
      ] // 库存
    }
  },
  computed: {
    // 类目字符串
    categoryActiveStr() {
      let result = ''
      let listName = this.categoryActiveList.map((item) => item.name)
      result = listName.join(' / ')
      return result // 类目名称拼接成字符串
    },
    // 类目末级选中
    categoryActiveObj() {
      let result = ''
      let len = this.categoryActiveList.length
      if (len) result = this.categoryActiveList[len - 1]
      return result
    }
  },
  watch: {
    categoryActiveObj(newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        if (newVal.has_children) {
          this.categoryLastId = ''
        } else {
          this.categoryLastId = newVal.id
          this.$nextTick(() => {
            this.initModuleData()
          })
        }
      }
    }
  },
  mounted() {
    this.getCategoryRouteIds()
  },
  methods: {
    // 获取路由类目ids
    getCategoryRouteIds() {
      this.categoryIsLoad = false
      let idsStr = this.$route.query.ids
      let idsList = []
      if (idsStr) idsList = idsStr.split(',')
      this.categoryRouteIds = idsList.map((item) => Number(item))
      this.categoryIsLoad = true
    },
    // 获取选中类目列表
    getCategoryActiveList(list) {
      this.categoryActiveList = []
      list.forEach((itemSt) => {
        itemSt.category.forEach((itemNd) => {
          if (itemSt.title.active_id === itemNd.id) {
            this.categoryActiveList.push(this.$util.deepCopy(itemNd))
          }
        })
      })
    },
    // 初始化模块数据
    initModuleData() {
      this.$refs.refModuleGoods.initTableOptions()
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  padding-bottom: 10px;
  .category-box {
    .category-item {
      display: flex;
      & ~ .category-item {
        margin-top: 5px;
      }
      .category-key,
      .category-val {
        position: relative;
        z-index: 9;
      }
      .category-key {
        width: 107px;
        padding: 5px 5px 5px 0;
        line-height: 28px;
        text-align: right;
        background: #f8f8f9;
        border: 1px solid #e4e7ed;
        border-radius: 2px 0 0 2px;
      }
      .category-val {
        min-width: 806px;
        padding: 5px;
        margin-left: -1px;
        border: 1px solid #e4e7ed;
        border-radius: 0px 2px 2px 0;
        .category-input {
          width: 793px;
          height: 26px;
          line-height: 26px;
          padding: 0 5px;
          border: 1px solid #ddd;
          border-radius: 2px;
        }
      }
    }
  }

  /deep/ .iconfont {
    cursor: pointer;
    & ~ .iconfont {
      margin-left: 5px;
    }
  }

  /deep/ .vxe-table--body-wrapper {
    min-height: 30px !important;
  }

  /deep/ .row--hover {
    background: @colorBlue10;
  }
}
</style>
